/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://pixabay.com/photos/macintosh-computer-technology-2619617/"
  }, "썸네일 원본")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "가장 저렴한 예약 시기를 알려주는 ", React.createElement(_components.a, {
    href: "https://www.cntraveler.com/story/google-flights-launches-new-feature-to-reveal-the-cheapest-time-to-book"
  }, "구글 Flights")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.google.com/travel/flights"
  }, "Google Travel")), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.deepl.com/translator"
  }, "DeepL 한국 결제 지원")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "커맨드라인 도구인 ", React.createElement(_components.a, {
    href: "https://fig.io/blog/post/fig-joins-aws"
  }, "fig.io를 AWS가 인수")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.hashicorp.com/blog/hashicorp-adopts-business-source-license"
  }, "Terraform BSL로 이전")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://opentf.org/"
  }, "OpenTF 발족"), " - CNCF 승인, Linux Foundation 합류를 목표로"), "\n", React.createElement(_components.li, null, "Gruntwork, Spacelift 등 127개 회사 참여, 10개 프로젝트, 1개 재단, 수많은 개인들"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/orgs/opentffoundation/projects/3?query=is%3Aopen+sort%3Aupdated-desc"
  }, "로드맵")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://typefully.com/iamvlaaaaaaad/kWKNoBH"
  }, "OpenTF is disconnected from reality")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.itworld.co.kr/topnews/303775"
  }, "하시코프의 라이선스 변경과 오픈소스의 본질(itworld)")), "\n"), "\n"), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
